// Custommize button
// varian : primary,outlined,tertiary,disbaled
a.btn{
  display: inline-block;
}
.btn {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  cursor: pointer;
  padding: 8px 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
  transition: 0.2s all ease;

  &:focus {
    outline: 3px solid rgba(34, 58, 94, 0.2);
  }

  &.btn-inline-block{
    display: inline-block;
  }

  &.btn-block {
    width: 100%;
    height: 100%;
    display: inline-block;
    text-align: center;
  }
  &.btn-w-icon {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &.btn-primary {
    background: #223a5e;
    border: 1px solid #223a5e;
    color: #ffffff;

    &:hover {
      background: #1b3863;
      border: 1px solid #1b3863;
    }
    &:active {
      background: #111c2e;
      border: 1px solid #111c2e;
    }
  }
  &.btn-outlined {
    background: #ffffff;
    border: 1px solid #223a5e;
    color: #223a5e;
    &:hover {
      background: #f5f5f5;
      border: 1px solid #e0e0e0;
    }
    &:active {
      background: #ededed;
      border: 1px solid #ededed;
    }
  }

  &.btn-tertiary {
    background: #1bbc34;
    border: 1px solid #1bbc34;
    color: #ffffff;
    
    &:hover {
      background: #208a30;
      border: 1px solid #208a30;
    }
    &:active {
      background: #12521c;
      border: 1px solid #12521c;
    }
  }

  &.btn-disbaled {
    background: #ededed;
    border: 1px solid #ededed;
    color: #9e9e9e;
    cursor: default;
  }

  &.btn-red {
    background: #bc3b1b;
    border: 1px solid #bc3b1b;
    color: #ffffff;
    &:hover {
      background: #8a2020;
      border: 1px solid #8a2020;
    }
    &:active {
      background: #521212;
      border: 1px solid #521212;
    }
  }
}

.ant-btn[disabled] {
  background: $disabledColor;
  border-color: $disabledColor;

  span {
    color: $textColorSecondary;
  }

  &:hover,
  &:focus {
    span {
      color: $textColorSecondary;
    }
    background: $disabledColor;
    border-color: $disabledColor;
    box-shadow: 0px 8px 30px rgba(136, 136, 136, 0.356);
  }
}
