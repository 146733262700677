.container {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  height: 100vh;
  background-color: #213a5e;
  width: auto;
  padding: 80px 0 40px;
  z-index: 99;
}
.inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  /* height: 100%; */
  width: 300px;
}
.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;
}
.logo img {
  padding-bottom: 10px;
}
.logo h1 {
  color: white;
  font-size: 18px;
}
.logo h1::first-letter {
  text-transform: capitalize;
}
.logo h2 {
  color: white;
  font-size: 16px;
  opacity: 50%;
}
