.container {
  padding-left: 350px;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-right: 40px;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 180%;
  letter-spacing: 0.0025em;
  color: #223a5e;
  margin-bottom: 20px;
  /* text-transform: uppercase; */
}
