// General Color
$primaryColor: #213a5e;
$secondaryColor: #1bbc34;

// Text Color
$textColorPrimary: #000000;
$textColorSecondary: #ffffff;
$disabledColor: #9e9e9e;

// Button Background Color
$btnPrimaryBg: #213a5e;
$btnSecondaryBg: #fcfdff;
$btnTertiaryBg: #1bbc34;
