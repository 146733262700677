#program-tambah {
  .dynamic-delete-button {
    position: relative;
    top: 46px;
    margin: 0 8px;
    color: #eb1414;
    font-size: 30px;
    cursor: pointer;
    transition: all 0.3s;
  }
  .dynamic-delete-button:hover {
    color: #8a2020d3;
  }
  .dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
  .btn-add-price {
    background-color: #1bbc34;
    color: white;
    border: 1px solid #1bbc34;
  }
  .btn-add-price:hover {
    background-color: #188127;
    color: white;
    border: 1px solid #188127;
  }

  .devInfoCard {
    background-color: #8fdafd;
    margin-bottom: 30px;
    .devInfo {
      display: flex;
      align-items: center;
      flex-direction: row;
      p {
        font-weight: 600;
      }
      span {
        padding: 10;
      }
    }
  }
}
