#program-page__filter-category {
  .ant-select {
    height: 100%;
    display: flex;
    align-items: center;
    .ant-select-selector {
      background-color: #ffffff !important;
      border-radius: 6px;
      height: 100%;
      align-items: center;
      width: 300px;

      .ant-select-selection-search {
        input {
          height: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  #program-page__filter-category {
    width: 100%;
    .ant-select {
      height: 100%;
      display: flex;
      align-items: center;
      .ant-select-selector {
        background-color: #ffffff !important;
        border-radius: 6px;
        height: 100%;
        align-items: center;
        width: 100%;

        .ant-select-selection-search {
          input {
            height: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  #program-page__filter-category {
    width: 100%;
    .ant-select {
      min-height: 40px;
      display: flex;
      align-items: center;
      .ant-select-selector {
        background-color: #ffffff !important;
        border-radius: 6px;
        min-height: 40px;
        align-items: center;
        width: 100%;

        .ant-select-selection-search {
          input {
            min-height: 40px;
          }
        }
      }
    }
  }
}
