.container {
  /* background-color: antiquewhite; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  text-transform: capitalize;
}

.container h1 {
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  color: #223a5e;
  margin-bottom: 60px;
}

.container img {
  width: 100px;
  margin-bottom: 20px;
}

.name {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  color: #223a5e;
}

.role {
  font-size: 18px;
  opacity: 50%;
}
