@import '../../../assets/styles/colors';

#sidebar-menu {
  .ant-menu {
    background-color: $primaryColor;
    border-right: 1px solid $primaryColor;
    color: #ffff;
    .ant-menu-submenu {
      &.ant-menu-submenu-selected,
      &.ant-menu-submenu-active,
      &.ant-menu-submenu-open {
        background-color: #e6f7ff;
        &::after {
          border-right: 3px solid $secondaryColor;
        }
        .ant-menu-submenu-title {
          color: $secondaryColor;
          i {
            color: $secondaryColor;
          }
        }
      }
      .ant-menu-submenu-title {
        font-weight: 600;
        font-size: 16px;
        // text-transform: uppercase;
        &:hover {
          color: $secondaryColor;
        }
        i {
          color: #ffff;
        }
      }
    }
    .ant-menu-item {
      font-size: 16px;
      font-weight: 600;
      // text-transform: uppercase;
      &.ant-menu-item-only-child {
        height: 50px;
      }
      &.ant-menu-item-selected {
        &::after {
          border-right: 3px solid $secondaryColor;
        }
        a {
          color: $secondaryColor;
        }
      }
      &:hover {
        color: $secondaryColor;
      }
      a {
        color: #ffff;
      }
    }
  }
}
