.container {
  display: grid;
  place-items: center;
}

.card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 20px;
  border-radius: 6px;
  width: 50%;
}

.title {
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 180%;
  letter-spacing: 0.0025em;
  color: #223a5e;
  margin-bottom: 20px;
  /* text-transform: uppercase; */
}

ol,
ul {
  margin-left: 20px;
}
