#programEditPage {
  .devInfoCard {
    background-color: #ffd152;
    .devInfo {
      display: flex;
      align-items: center;
      flex-direction: row;
      p {
        font-weight: 600;
      }
      span {
        padding: 10;
      }
    }
  }
}
