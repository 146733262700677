#program-page__search-program {
  .ant-select {
    width: 100%;
  }
  .ant-input-affix-wrapper {
    padding: 10px 10px 10px 21px;
    border-radius: 6px;
    background-color: #ffffff;
    
    // Search Icon
    .ant-input-prefix {
      position: relative;
      margin-right: 18px;
      padding-right: 18px;
      &::after {
        content: '';
        position: absolute;
        height: 80%;
        border-right: 1px solid #a0a0a0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      .search-icon {
        display: flex;
        align-items: center;
        .anticon .anticon-search {
          font-size: 16px;
        }
        .icon-divider {
          rect {
            fill: #a0a0a0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  #page__search-program {
    width: 100%;
    .ant-input-affix-wrapper {
      max-width: none;
    }
  }
}

@media only screen and (max-width: 600px) {
  #program-page__search-program {
    .ant-input-affix-wrapper {
      padding: 3px 10px 3px 19px;
      height: 40px;
      // Search Icon
      .ant-input-prefix {
        position: relative;
        margin-right: 20px;
        padding-right: 20px;
        &::after {
          content: '';
          position: absolute;
          height: 100%;
          border-right: 1px solid #a0a0a0;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        .search-icon {
          display: flex;
          align-items: center;
          .anticon .anticon-search {
            font-size: 16px;
          }
          .icon-divider {
            rect {
              fill: #a0a0a0;
            }
          }
        }
      }
    }
  }
}
