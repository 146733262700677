// ==================
// LIST ANTD STYLING
// CUSTOM ANTD DRAWER
// CUSTOM ANTD SELECT
// CUSTOM ANTD BUTTON
// CUSTOM ANTD TABEL
// ==================

// CUSTOM ANTD DRAWER

.ant-drawer-mask {
  background: rgba(34, 58, 94, 0.5);
}

// CUSTOM ANT SELECT
.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: none !important;
  box-shadow: none !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: inherit;
}

// BUTTON
.ant-btn {
  height: 40px;
  border-radius: 6px;
  padding: 8px 16px;
  &.ant-btn-default {
    background: #ffffff;
    border: 1px solid #223a5e;
    color: #223a5e;

    &:hover {
      background: #f5f5f5;
      border: 1px solid #e0e0e0;
    }

    &:active {
      background: #ededed;
      border: 1px solid #ededed;
    }
  }
  &.ant-btn-primary {
    background: #223a5e;
    border: 1px solid #223a5e;
    color: #ffffff;

    &:hover {
      background: #1b3863;
      border: 1px solid #1b3863;
    }
  }

  &.ant-btn-red {
    background: #bc3b1b;
    border: 1px solid #bc3b1b;
    color: #ffffff;
    &:hover {
      background: #8a2020;
      border: 1px solid #8a2020;
    }
    &:active {
      background: #521212;
      border: 1px solid #521212;
    }
  }

  &.ant-btn-green {
    background: #1bbc34;
    border: 1px solid #1bbc34;
    color: #ffffff;
    &:hover {
      background: #208a30;
      border: 1px solid #208a30;
    }
    &:active {
      background: #208a30;
      border: 1px solid #208a30;
    }
  }

  &.ant-btn-orange {
    background: #faad14;
    border: 1px solid #faad14;
    color: #ffffff;
    &:hover {
      background: #d48806;
      border: 1px solid #d48806;
    }
    &:active {
      background: #d48806;
      border: 1px solid #d48806;
    }
  }
}

// CUSTOM ANTD TABEL
.ant-table {
  margin-top: 20px;
  border-radius: 50px;
}

// custom antd upload
.ant-upload {
  &.ant-upload-select-picture-card {
    width: 100%;
    height: 100%;
  }
}

// custom modal confirm
.ant-modal-confirm {
  top: 0;
  display: inline-block;
  text-align: center;
  padding-bottom: 0;
  vertical-align: middle;

  .ant-modal-content {
    position: relative;
    background-color: #ffff;
    background-clip: padding-box;
    border: 0;
    border-radius: 10px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: auto;
  }

  .ant-modal-body {
    padding: 24px;
    padding-top: 50px;
    font-size: 21px;
    line-height: 1.5715;
    font-weight: 500;
    word-wrap: break-word;
    text-align: center;
  }
  .ant-modal-footer {
    padding: 24px;
    padding-bottom: 50px;
    text-align: center;
    background: transparent;
    border-top: 0;
    border-radius: 0;
  }
  .ant-btn {
    min-width: 130px;
  }
}
